import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import thunk from "redux-thunk";
import { reducer } from "./reducer";
import { createLogger } from "redux-logger";

const logger = createLogger({});
const migrations = {
  0: (state) => {
    // migration to keep only device state
    console.log("statestate", state);
    return {
      logindata:{
          val:"123456"
      }
    };
  },
};
const persistConfig = {
  key: "root",
  storage: storage,
  version: 1,
  stateReconciler: autoMergeLevel2,
    // migrate: createMigrate(migrations, { debug: true })
  migrate: (state) => {
    console.log("Migration Running!",state);
    return Promise.resolve(state);
  },
};
const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(
  persistedReducer,
  {},
  applyMiddleware(thunk, logger)
);
export const persistor = persistStore(store);
