export function Login(res) {
    return { type: 'Login', res };
}
export function Logout() {
    return { type: 'Logout' };
}

export function Orawan(res) {
    return { type: 'Orawan',res };
}

export function Orabase(res) {
    return { type: 'Orabase',res };
}


