import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import { connect } from "react-redux";
import { OraWanIpAddress } from "./common/constant";
import { bindActionCreators } from "redux";
import { ActCreators } from "./views/redux/bindActionCretor";
 var PubNub = require("pubnub");
export const pubnub = new PubNub({
  publishKey: "pub-c-e53ff057-5f83-4713-9e82-0155f0a17443",
  subscribeKey: "sub-c-97937796-6917-11ea-94ed-e20534093ea4",
  uuid:"Myscript"
});

let LoginData = {};
const mapStateToProps = state => {
  console.log("console.lo", state);
  LoginData = state.logindata;
  pubnub.subscribe({
    channels: [LoginData.orawan_device,LoginData.orabase_id],
    withPresence: true,
     // resubscribe and reconnect
     restore: true,
     // message received
     callback: function () {
       console.log("callback");
     },
     //  conneciton lost
     disconnect: function () {
       console.log("Connection Lost!");
     },
     // connection restored
     reconnect: function () {
       console.log("And we're Back!");
     },
     // connection established
     connect: function () {
       // send a message
       console.log("connected done");
     },
  },function(status,response){

    console.log("status,reponse",status,response)

  }

  );

};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActCreators, dispatch);
};



const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

const Login = React.lazy(() => import("./views/Pages/Login/index"));
export let wanSocket = null;
export let oraBaseSocket = null;
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertShow: false,
      alertData: []
    };
  }

  componentDidMount() {
    console.log("ananananand",LoginData.orawan_device,LoginData.orabase_id);

    var this_pointer=this;


    pubnub.addListener({
      status: function(status) {
        console.log("statusutsus", status);
      },
      message: function(msg) {
        console.log("msg", msg);
        if(msg.channel!="pubnub-orabase"){
          if(msg.channel==LoginData.orabase_id){

            this_pointer.props.Orabase(msg)

          }else{
            this_pointer.props.Orawan(msg)
          }
        }





      },
      presence: function(presenceEvent) {
        console.log(presenceEvent, "presenceEvent");
      },
      signal: function(s){
        console.log("signal",s)
      },
      messageAction: function(m){
        console.log("messageAction",m)
      },

    });

    //wanSocket = new window.WebSocket(OraWanIpAddress);
    // oraBaseSocket = new window.WebSocket(
    //   LoginData && LoginData.orabase_ip
    //     ? "ws://" + LoginData.orabase_ip + ":3030"
    //     : OraBaseIpAddress
    // );
  }

  componentWillUnmount(){
    pubnub.unsubscribe({
      channel: [LoginData.orawan_device,LoginData.orabase_id],
    });
  }

  render() {
    return (
      <>
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                path="/login"
                name="Login"
                render={props => <Login {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={props => <DefaultLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
