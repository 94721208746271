import { REHYDRATE } from "redux-persist";

var initState = {
  logindata: {},
  orawanData: {},
  orabaseData:{}
};

function loginreducer(state = initState, action) {
  switch (action.type) {
    case "Login":
      return {
        ...state,
        logindata: action.res
      };
    case "Logout":
      return {
        ...state,
        logindata: {}
      };

    case "Orawan":
      return {
        ...state,
        orawanData: action.res
      };

      case "Orabase":
        return {
          ...state,
          orabaseData: action.res
        };

    case REHYDRATE:
      return {
        ...state,
        logindata:
          action.payload && action.payload.logindata
            ? action.payload.logindata
            : {},
        orawanData: {},
        orabaseData:{}
      };
    default:
      return {
        ...state
      };
  }
}

export const reducer = loginreducer;
