import { store } from "../views/redux/store";
const storeData = store.getState();
console.log("storeData", storeData);
const local = "http://localhost:9000/api/";

const live = "https://app.oratag.com/api/";
export const OraBaseIpAddress =
  "wss://" + (storeData && storeData.logindata && storeData.logindata.orabase_id
    ? storeData.logindata.orabase_id
    : +"") + ":3030";
export const OraWanIpAddress = "wss://3.14.71.250:8080";

export const url = live ;

export const scan_duration_ms=10000;

//Assign OraTags

export const assetContent = {
  url: url + "createTransaction",
  method: "POST"
};

export const assetItems = {
  url: url + "addContent",
  method: "POST"
};

export const deleteAssetItems = {
  url: url + "deleteContent/",
  method: "DELETE"
};

export const checkOraTagStatus = {
  url: url + "checkOraTagStatus",
  method: "POST"
};

export const assignOraTagData = {
  url: url + "updateOraTagStatus",
  method: "POST"
};

export const cancelTransaction = {
  url: url + "cancelTransaction/",
  method: "GET"
};

//SearchOraTags
export const searchOraTagData = {
  url: url + "searchOraTag",
  method: "POST"
};

export const exportExcel = {
  url: url + "downloadData",
  method: "GET"
};


//Login

export const LoginData = {
  
  url: url + "login",
  method: "POST"
};


export const SettingConfigurationData = {
  url: url + "getUserDetails",
  method: "GET"
};

export const UpdateSettingConfigurationData = {
  url: url + "userUpdate",
  method: "POST"
};


export const getOrabaseStatus = {
  url: url + "getPiOrabaseStatus/",
  method: "GET"
};



//getOrabasePiStatus

export const getOrabasePiStatus = {
  url: url + "getOrabaseStatus/",
  method: "GET"
};
